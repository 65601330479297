<template>
  <div class="vue-box">
    <div class="c-panel">
      <!-- 参数栏 -->
      <div class="c-title">支付宝分组管理</div>
      <el-form :inline="true" size="mini">
        <el-form-item>
          <el-button type="primary" icon="el-icon-plus" @click="drawerVisible = true">添加分组</el-button>
          <el-button type="success" icon="el-icon-refresh" @click="
              f5();
            ">刷新
          </el-button>
        </el-form-item>
        <br />
      </el-form>
      <div class="c-title">数据列表</div>
      <el-table :data="dataList" size="mini" :header-cell-style="{ 'text-align': 'center' }"
                :cell-style="{ 'text-align': 'center' }">
        <el-table-column label="编号" prop="id"></el-table-column>
        <el-table-column label="分组名" prop="groupName" />
        <el-table-column label="创建时间" prop="createdTime" />
        <el-table-column prop="address" label="操作" width="150px" fixed="right">
          <template slot-scope="s">
            <el-button class="c-btn" type="success" icon="el-icon-edit" @click="update(s.row)">编辑</el-button>
            <el-button class="c-btn" icon="el-icon-delete" type="danger" @click.native="del(s.row)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>

      <el-drawer :append-to-body="true" :wrapperClosable="false" :visible.sync="drawerVisible" size="35%" direction="rtl">
        <div>
          <el-form :model="m" label-width="120px" :rules="rules" ref="groupForm">
            <el-form-item label="id"  v-show="m.id">
              <el-input v-model="m.id" disabled style="width: 300px" />
            </el-form-item>
            <el-form-item label="分组名称:" prop="groupName">
              <el-input v-model="m.groupName" style="width: 300px" />
            </el-form-item>
            <el-form-item>
              <span class="dialog-footer">
                <el-button @click="drawerVisible=false">取 消</el-button>
                <el-button type="primary" @click="upsert()">提 交</el-button>
              </span>
            </el-form-item>
          </el-form>
        </div>
      </el-drawer>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      m: {
        id: '',
        groupName: '',
      },
      drawerVisible: false,
      dataCount: 0,
      dataList: [],
      rules: {
        groupName: [
          { required: true, message: '请输入支付宝名称', trigger: 'blur' },
        ],
      },
    }
  },
  methods: {
    // 数据刷新
    f5: function () {
      var defaultCfg = {
        type: 'get', // 默认请求类型
      }
      this.sa.ajax(
        '/v1/alipay/config/group/simple-list',
        function (res) {
          this.dataList = res.data // 数据
        }.bind(this),
        defaultCfg,
      )
    },
    del: function (data) {
      var defaultCfg = {
        type: 'get', // 默认请求类型
      }
      this.sa.ajax(
        '/v1/alipay_profitshare_account/delete/' + data.id,
        function () {
          //刷新数据
          this.f5()
        }.bind(this),
        defaultCfg,
      )
    },
    update: function (data) {
      this.m = data
      this.drawerVisible = true
    },
    upsert: function () {
      var defaultCfg = {
        isBody: true, //是否是请求体请求
      }
      this.$refs['groupForm'].validate((valid) => {
        if (valid) {
          this.sa.ajax(
            '/v1/alipay/config/group/upsert',
            this.m,
            function () {
              this.drawerVisible = false
              this.f5()
            }.bind(this),
            defaultCfg,
          )
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
  },
  created: function () {
    this.f5()
  },
  directives: {
    'el-select-loadmore': {
      bind (el, binding) {
        const SELECTWRAP_DOM = el.querySelector(
          '.el-select-dropdown .el-select-dropdown__wrap',
        )
        SELECTWRAP_DOM.addEventListener('scroll', function () {
          const condition =
            this.scrollHeight - this.scrollTop <= this.clientHeight
          if (condition) {
            binding.value()
          }
        })
      },
    },
  },
}
</script>

<style>
</style>
